// Imports

import Vue from 'vue'
import App from '@/App.vue'
import authHeader from "@/services/auth";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueOnToast from 'vue-on-toast'
import isMobile from 'mobile-device-detect'
import isTablet from 'mobile-device-detect'
import router from '@/router'
import store from '@/store'
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import mapInit from "highcharts/modules/map";
import Treemap from 'highcharts/modules/treemap'
import HighchartsVue from "highcharts-vue";
import VueMeta from "vue-meta";

console.log = () => {};
console.error = () => {};
console.warn = () => {};
console.info = () => {};
console.debug = () => {};

stockInit(Highcharts);
mapInit(Highcharts);
Treemap(Highcharts);

Vue.use(HighchartsVue);
Vue.use(VueAxios, axios)
Vue.use(VueOnToast)
Vue.use(isMobile)
Vue.use(isTablet)
Vue.use(VueMeta)

// Main code; function calls

/**
 * Code executed on each vue-router redirection before the redirection takes effect.
 * The redirection will take effect on "next()" call
 */
router.beforeEach(async (to, from, next) => {
  const router_comes_from_same_site = from.path != null;
  const router_is_pointing_to_login = to.name == "Login";
  const router_is_pointing_to_informe_flow = to.name == "InformeFlow";
  const router_is_pointing_to_informe_subscribe = to.name == "InformeSubscribe";

  window.sessionStorage.removeItem('ticker_search_by_user');
  // Wait until user info is loaded before proceeding
  const waitForUserInfo = () => {
    return new Promise((resolve) => {
      const checkUserInfo = () => {
        if (store.state.user_info_loaded) {
          resolve();
        } else {
          setTimeout(checkUserInfo, 100);
        }
      };
      checkUserInfo();
    });
  };

  if (router_is_pointing_to_login) {
    if (router_comes_from_same_site) {
      storePreviousLocation(from.name);
    }
    next();
  }
  else if (router_is_pointing_to_informe_flow) {
    await waitForUserInfo();
    // InformeFlow is for non-logged in users
    if (store.state.user_id !== 0) {
      // If logged in, redirect to InformeSubscribe (if they don't have access already)
      if (store.state.user_features.has_informe_access) {
        router.push({ name: 'informes' });
      } else {
        router.push({ name: 'InformeSubscribe' });
      }
    }
    else {
      next();
    }
  }
  else if (router_is_pointing_to_informe_subscribe) {
    await waitForUserInfo();
    // InformeSubscribe is for logged in users without access
    if (store.state.user_id === 0) {
      // If not logged in, redirect to InformeFlow for registration
      router.push({ name: 'InformeFlow' });
    }
    else if (store.state.user_features.has_informe_access) {
      // If they already have access, redirect to informes
      router.push({ name: 'informes' });
    }
    else {
      next();
    }
  }
  else {
    next();
  }
});

/**
 * Code executed on each vue-router redirection after the redirection takes effect.
 */
// router.afterEach((to, from) => {
//   refreshPageIfNeeded();
// })

// function refreshPageIfNeeded() {
//   const page_needs_reloading = localStorage.getItem('page_needs_reloading') == 'true';

//   if (page_needs_reloading) {
//     localStorage.setItem('page_needs_reloading', false);
//     location.reload();
//   }
// }

/**
 * Set up main vue app
 */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//automatic detect environment function to links (redirect to VUE)
Vue.prototype.$detectEnvironment = function () {
  const environment = process.env.NODE_ENV;
  if (environment === "development") {
    return "http://192.168.208.2";
  } else {
    return process.env.VUE_APP_API;
  }
}

function storePreviousLocation(previous_location_name) {
  //const previous_location_store = localStorage.getItem('user_location_before_login');
  //const previous_location_exists_in_store = previous_location_store
  //  && previous_location_store != null
  //  && previous_location_store != 'null';
  //if (!previous_location_exists_in_store) {
  localStorage.setItem('user_location_before_login', previous_location_name);
  //}
}